import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";

import "./Idea.scss";

const Idea = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const ideaTl = gsap
      .timeline()
      .to(
        ".idea",
        {
          yPercent: 40,
          duration: 1,
        },
        0
      )
      .to(
        ".idea-title",
        {
          yPercent: -100,
          duration: 1,
        },
        0
      );

    ScrollTrigger.create({
      animation: ideaTl,
      trigger: ".idea",
      start: "top",
      end: "bottom",

      scrub: true,
    });
  }, []);

  return (
    <section className="idea">
      <div
        className="container"
        data-aos="zoom-in"
        data-aos-duration="500"
        data-aos-offset="500"
      >
        <h1 className="idea-title" data-aos="zoom-out" data-aos-delay="500">
          Realizza le tue idee
        </h1>
      </div>
    </section>
  );
};

export default Idea;
