import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import { useEffect } from "react";

import { useScrollTo } from "../../../hook/useScrollTo";
import "./Hero.scss";

const Hero = () => {
  const { scrollToServices } = useScrollTo();

  useEffect(() => {
    gsap.fromTo(
      ".hero .column",
      {
        y: 50,
        opacity: 0,
        duration: 1,
      },
      {
        y: 0,
        opacity: 1,
        duration: 1,
      }
    );

    // const heroTl = gsap.timeline({ autoplay: false });
    /* .to(
        ".hero",
        {
          yPercent: 50,
          duration: 1,
        },
        0
      ) */
    /* .to(
        ".hero .column",
        {
          yPercent: 100,
          duration: 1,
        },
        0
      ); */

    /* ScrollTrigger.create({
      animation: heroTl,
      trigger: ".hero",
      start: "top",
      end: "bottom",
      scrub: true,
    }); */
  });

  return (
    <section className="hero">
      <div className="row">
        <div className="column col-lg-12" data-aos="fade-down">
          <img
            src={require("../../../assets/images/white_logo.png")}
            alt="white-logo"
            className="logo"
          />
          <h1 className="title">Creatività al tuo servizio</h1>
          {/* <h4 className="subtitle"></h4> */}

          {/* <button className="go-to-services" onClick={scrollToServices}>
            Scopri di più
          </button> */}
        </div>
      </div>

      <button className="discover-more" onClick={scrollToServices}>
        <div className="scroll-down">Scopri di più</div>
        <FontAwesomeIcon icon={faAngleDown} />
      </button>

      <div className="cover-filter"></div>
    </section>
  );
};

export default Hero;
