import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer-container">
      <p className="footer-description"> @2024 micheletornello.com </p>
      <p className="footer-description">
        Tel:{" "}
        <a href="tel:+39 3341593912" className="footer-description-link">
          +393341593912
        </a>
      </p>
      <p className="footer-description">
        Email:{" "}
        <a href="mailto:micheletornello5@gmail.com" className="footer-description-link">
          micheletornello5@gmail.com
        </a>
      </p>

      <div className="footer-icons-container">
        <a
          href="https://www.facebook.com/micheletornellofreelancer"
          className="footer-link"
        >
          <FontAwesomeIcon icon={faFacebookF} className="footer-link-icon" />
        </a>
        <a
          href="https://www.instagram.com/michele_tornello/"
          className="footer-link"
        >
          <FontAwesomeIcon icon={faInstagram} className="footer-link-icon" />
        </a>
        <a
          href="https://www.linkedin.com/in/michele-tornello-06a6341aa/"
          className="footer-link"
        >
          <FontAwesomeIcon icon={faLinkedinIn} className="footer-link-icon" />
        </a>
        <a
          href="https://ludelist.com/account/profile/1"
          className="footer-link"
        >
          <img
            src={require("../../../assets/images/ludelist_logo_marketing.png")}
            alt="ludelist_footer_logo"
            className="ludelist_logo"
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
