import gsap from "gsap";
import { useEffect, useLayoutEffect } from "react";

import "./Loader.scss";

const Loader = () => {
  useEffect(() => {
    document.querySelector("body")!.style.overflowY = "hidden";

    gsap.to("html", {
      onComplete: () => {
        gsap.to(".loader", {
          display: "none",
          opacity: 0,
          duration: 1,
        });
      },
    });

    document.querySelector("body")!.style.overflowY = "auto";
  }, []);

  return (
    <div className="loader">
      <img
        src="https://cdn.dribbble.com/users/116693/screenshots/1998212/loader.gif"
        className="loader_img"
        alt="loader"
      />
    </div>
  );
};

export default Loader;
