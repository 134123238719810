import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";

import "./Portfolio.scss";

const Portfolio = () => {
  useEffect(() => {
    const portfolioTl = gsap.timeline().fromTo(
      ".portfolio-titles, .portfolio .web-sites .column",
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.1,
        duration: 0.5,
      }
    );

    ScrollTrigger.create({
      animation: portfolioTl,
      trigger: ".portfolio",
      start: "top center",
      end: "bottom 80%",

      scrub: true,
    });
  }, []);

  return (
    <section className="portfolio" id="portfolio">
      <div className="container">
        <h1
          className="portfolio-titles"
          id="portfolio-title"
          data-aos="fade-down"
        >
          Portfolio
        </h1>

        <h2 className="portfolio-titles" id="row-title" data-aos="fade-down">
          SITI WEB in evidenza
        </h2>
        <div className="row web-sites">
          <a
            href="https://recsecurityservice.it/"
            target="_blank"
            rel="noopener noreferrer"
            className="column col-lg-3"
            data-aos="fade-right"
          >
            <img
              src={require("../../../assets/images/portfolio/siti_web/rec_security.webp")}
              alt="rec-security-website"
            />
            <p>Rec Security Service</p>
          </a>
          <a
            href="https://www.casavacanzekastalia.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="column col-lg-3"
            data-aos="fade-down"
          >
            <img
              src={require("../../../assets/images/portfolio/siti_web/casavacanzekastalia.webp")}
              alt="casa-vacanze-kastalia-website"
            />
            <p>Casa Vacanze Kastalia</p>
          </a>
          <a
            href="https://ludelist.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="column col-lg-3"
            data-aos="fade-left"
          >
            <img
              src={require("../../../assets/images/portfolio/siti_web/ludelist.webp")}
              alt="ludelist-website"
            />
            <p>LUDELIST</p>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
