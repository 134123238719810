import { useEffect } from "react";

import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";

export const useScrollTo = () => {
    let servicesOffset: number = 0;
    let portfolioOffset: number = 0;
    let meOffset: number = 0;
    let contactFormOffset: number = 0;

    useEffect(() => {
        gsap.registerPlugin(ScrollToPlugin);

        //SERVICES OFFSET
        servicesOffset = (
            document.querySelector("#services") as HTMLElement
        )?.getBoundingClientRect().top;

        //PORTFOLIO OFFSET
        portfolioOffset = (
            document.querySelector("#portfolio") as HTMLElement
        )?.getBoundingClientRect().top;

        //ME OFFSET
        meOffset = document.querySelector("#me")?.getBoundingClientRect().top || 0;

        //CONTACT FORM OFFSET
        contactFormOffset = (document.querySelector("#contact-form") as HTMLElement)
            ?.offsetTop;
    }, []);

    const scrollToServices = () => {
        gsap.to("html", {
            scrollTo: servicesOffset + 50,
            duration: 1,
        });
    };

    const scrollToPortfolio = () => {
        gsap.to("html", {
            scrollTo: portfolioOffset + 1800,
        });
    };

    const scrollToMe = () => {
        gsap.to("html", {
            scrollTo: meOffset - 50,
        });
    };

    const scrollToContacts = () => {
        gsap.to("html", {
            scrollTo: contactFormOffset - 50,
        });
    };

    return { scrollToServices, scrollToPortfolio, scrollToMe, scrollToContacts };
};
