import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";
import { useScrollTo } from "../../../hook/useScrollTo";

import "./Activity.scss";

const Activity = () => {
  const { scrollToServices } = useScrollTo();

  useEffect(() => {
    const activityTl = gsap
      .timeline()
      .fromTo(
        ".activity",
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
        },
        "activity-entry"
      )
      .fromTo(
        ".activity-content",
        {
          x: -200,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          duration: 1,
        },
        "activity-entry"
      )
      .fromTo(
        ".activity-title, .activity-description, .activity .go-to-services",
        {
          y: 50,
          opacity: 0,
        },
        {
          y: 0,
          stagger: 0.1,
          opacity: 1,
          duration: 1,
          delay: 0.4,
        },
        "activity-entry"
      )
      .to(".activity", {
        delay: 2,
      })
      .to(".activity", {
        opacity: 0,
        duration: 2,
      });

    ScrollTrigger.create({
      animation: activityTl,
      trigger: ".activity",
      start: "top",
      end: "bottom+=1000",

      scrub: true,
      pin: true,
    });
  }, []);

  return (
    <section className="activity">
      <div className="row">
        <div className="col-lg-6 activity-content" data-aos="fade-left">
          <div className="container">
            <h1 className="activity-title">Fai crescere la tua attività</h1>
            <p className="activity-description">
              Oggi è molto importante avere una buona visibilità su internet, la
              gente può instantaneamente capire cosa proponi tramite un sito web
              o un social network.
              <br />
              Puoi associare video e immagini all'avanguardia per far conoscere
              meglio la tua attività e per tua fortuna puoi trovare tutto qui...
            </p>

            <button className="go-to-services" onClick={scrollToServices}>
              Scopri di più
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Activity;
