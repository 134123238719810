import axios from "axios";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect, useState } from "react";

import "./ContactForm.scss";

const ContactForm = () => {
  const [email, setEmail] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const sendEmail = async (event: any) => {
    try {
      event.preventDefault();
      
      await axios.post("https://micheletornello.com/mail.php", {
        email,
        subject,
        message,
      });

      alert("Email inviata correttamente!");
      
    } catch (error) {
      alert("Errore Riprova!");
    }
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const contactFormTl = gsap.timeline().fromTo(
      ".contact-form-title, .contact-form-description, .contact-form-input, .contact-form-textarea, .contact-submit-button",
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.1,
        duration: 1,
      }
    );

    ScrollTrigger.create({
      animation: contactFormTl,
      trigger: "#contact-form",
      start: "top-=300",
      end: "top",

      scrub: true,
    });
  }, []);

  return (
    <section className="contact-form-container p-5" id="contact-form">
      <div className="row">
        <div className="col-lg-6 contacts" data-aos="fade-right">
          <h1 className="contact-form-title">Contatti</h1>
          <p className="contact-form-description">
            <span className="contact-form-description-title"> Email </span>{" "}
            micheletornello5@gmail.com
          </p>
          <p className="contact-form-description">
            <span className="contact-form-description-title"> Tel. </span> +39
            3341593912
          </p>
        </div>

        <form
          onSubmit={sendEmail}
          className="col-lg-6 contact-form"
          data-aos="fade-left"
        >
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="contact-form-input email"
            value={email} onChange={({target: {value}})=> setEmail(value)}
            required
          />

          <input
            type="text"
            name="oggetto"
            placeholder="Oggetto"
            className="contact-form-input oggetto"
            value={subject} onChange={({target: {value}})=> setSubject(value)}
            required
          />

          <textarea
            name="messaggio"
            placeholder="Messaggio"
            className="contact-form-textarea messaggio"
            value={message} onChange={({target: {value}})=> setMessage(value)}
            rows={4}
          ></textarea>

          <button type="submit" className="contact-submit-button">
            Invia
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
