import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";

import { useScrollTo } from "../../../hook/useScrollTo";

import "./Me.scss";

const Me = () => {
  const { scrollToContacts } = useScrollTo();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const meTl = gsap.timeline();

    ScrollTrigger.matchMedia({
      "(max-width: 768px)": () => {
        meTl
          .fromTo(
            ".me",
            {
              opacity: 0,
            },
            {
              opacity: 1,
              duration: 1,
            }
          )
          .fromTo(
            ".me-title, .me-description, .me-btn",
            {
              y: 100,
              opacity: 0,
            },
            {
              y: 0,
              opacity: 1,
              duarion: 5,
              stagger: 0.1,
            }
          );
        /* .to(
            ".me",
            {
              yPercent: 0,
              duration: 1,
            },
            "me-parallax"
          )
          .to(
            ".me .text-container",
            {
              yPercent: -20,
              duration: 1,
            },
            "me-parallax"
          ); */
      },
      "(min-width: 768px)": () => {
        meTl
          .fromTo(
            ".photo-container",
            {
              xPercent: 50,
              opacity: 0,
            },
            {
              xPercent: 0,
              opacity: 1,
              duration: 0.8,
            },
            0
          )
          .fromTo(
            ".me-title, .me-description, .me-btn",
            {
              y: 100,
              opacity: 0,
            },
            {
              y: 0,
              opacity: 1,
              stagger: 0.1,
              delay: 0.2,
            },
            0
          );
      },
    });
    /* .fromTo(
        ".photo-container",
        {
          xPercent: 50,
          opacity: 0,
        },
        {
          xPercent: 0,
          opacity: 1,
        },
        0
      ) */

    ScrollTrigger.create({
      animation: meTl,
      trigger: ".me",
      start: "top",
      end: "bottom",

      scrub: true,
      pin: true,
    });
  }, []);

  return (
    <section className="me" id="me">
      <div className="row">
        <div className="col-lg-4 photo-container" data-aos="fade-right">
          <img
            src={require("../../../assets/images/me.webp")}
            alt="me"
            className="me-img"
            data-anim="shadow-pop-tr"
            data-anim-delay="500"
          />
        </div>
        <div className="col-lg-8 text-container" data-aos="fade-left">
          <div className="row">
            <div className="col-lg-10">
              <h1 className="me-title">Chi Sono</h1>
              <p className="me-description">
                Sono Michele Tornello, la mia passione è l'informatica e tutto
                quello che è ad essa collegato.
                <br />
                Ormai sono anni che lavoro in questo settore e penso di poterti
                aiutare a realizzare ciò che vuoi, che sia per te o per la tua
                attività puoi stare tranquillo, perchè metto la professionalità
                al primo posto.
              </p>
              <div className="me-btn">
                <a href="CV.pdf" target="_blank" className="cv-btn">
                  GUARDA IL MIO CV
                </a>
                {/* <button
                  className="contact-me-btn"
                  id="contact-me-btn"
                  onClick={scrollToContacts}
                >
                  CONTATTAMI
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Me;
