import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";

import "./Services.scss";

const Services = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let servicesTl;
    let serviceScrollTriggerProps: {
      end: string;
      pin: boolean;
      snap: number;
    } = {
      end: "bottom+=4000",
      pin: true,
      snap: 0.2,
    };

    ScrollTrigger.matchMedia({
      "(max-width: 992px)": () => {
        servicesTl = gsap
          .timeline()
          .fromTo(
            ".services-title",
            {
              y: 100,
              opacity: 0,
            },
            {
              y: 0,
              opacity: 1,
            },
            0
          )
          /* .to(
            ".services-title",
            {
              y: -100,
              opacity: 0,
            },
            1
          ) */
          .fromTo(
            ".service-content-container",
            {
              yPercent: 100,
              opacity: 0,
            },
            {
              yPercent: gsap.utils.wrap([-20, -10, 0, 10, 20, 30]),
              duration: 0.5,
              opacity: 1,
              stagger: 1,
            },
            1
          )
          .to(
            ".service-content-container",
            {
              xPercent: gsap.utils.wrap([-50, -40, -30, -20, -10, 0]),
              opacity: gsap.utils.wrap([0.2, 0.2, 0.2, 0.2, 0.2, 1]),
              delay: 1,
              stagger: 1,
            },
            1
          );
      },

      "(min-width: 992px)": () => {
        servicesTl = gsap
          .timeline()
          .fromTo(
            ".services-title",
            {
              y: 100,
              opacity: 0,
            },
            {
              y: 0,
              opacity: 1,
            }
          )
          .fromTo(
            ".service-content-container",
            {
              y: 100,
              opacity: 0,
            },
            {
              y: 50,
              opacity: gsap.utils.wrap([1, 1, 1, 0, 0, 0]),
              stagger: 0.5,
            }
          )
          .to(".service-content-container", {
            x: gsap.utils.wrap([-50, -50, -50, 0, 0, 0]),
            y: gsap.utils.wrap([0, 0, 0, -400, -400, -400]),
            opacity: gsap.utils.wrap([0.2, 0.2, 0.2, 1, 1, 1]),
            stagger: 0.5,
            duration: 1,
            delay: 1,
          })
          .to(".services", {
            delay: 10,
          });

        serviceScrollTriggerProps = {
          end: "bottom+=1500",
          pin: true,
          snap: 0,
        };
      },
    });

    const { end, pin, snap } = serviceScrollTriggerProps;

    ScrollTrigger.create({
      animation: servicesTl,
      trigger: ".services",
      start: "top",
      end,

      pin,
      snap,

      scrub: true,
    });
  }, []);

  return (
    <section className="services" id="services">
      <h1 className="services-title" data-aos="fade-down">
        Di cosa hai bisogno?
      </h1>

      <div className="row">
        <div
          className="service-content-container col-lg-3"
          data-aos="fade-right"
        >
          <div className="service-content">
            <img
              src={require("../../../assets/images/services/web_development.webp")}
              alt="web-development"
            />
            <h2 className="service-title">Un fantastico Sito Web?</h2>
            <p className="service-description">
              Creazione di siti web personalizzati e professionali, con relativa
              ottimizzazione su molteplici piattaforme.
            </p>
          </div>
        </div>

        <div
          className="service-content-container col-lg-3 mx-4"
          data-aos="fade-down"
        >
          <div className="service-content">
            <img
              src={require("../../../assets/images/services/graphics.webp")}
              alt="graphics"
            />
            <h2 className="service-title">Una bella Grafica?</h2>
            <p className="service-description">
              Creazione di grafiche varie: Loghi, Locandine, Biglietti da
              visita, Fotoritocco, Photoshop Avanzato, ecc...
            </p>
          </div>
        </div>

        <div
          className="service-content-container col-lg-3"
          data-aos="fade-left"
        >
          <div className="service-content">
            <img
              src={require("../../../assets/images/services/video_making.webp")}
              alt="video-making"
            />
            <h2 className="service-title">Un Video Accattivante?</h2>
            <p className="service-description">
              Video per eventi o attività, anche in Motion Graphics!
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div
          className="service-content-container col-lg-3"
          data-aos="fade-right"
        >
          <div className="service-content">
            <img
              src={require("../../../assets/images/services/software.webp")}
              alt="web_development"
            />
            <h2 className="service-title">Un Software Personalizzato?</h2>
            <p className="service-description">
              Un fantastico software per soddisfare tutte le tue esigenze! Sia
              Desktop che Web App
            </p>
          </div>
        </div>

        <div
          className="service-content-container col-lg-3 mx-4"
          data-aos="fade-down"
        >
          <div className="service-content">
            <img
              src={require("../../../assets/images/services/3d.webp")}
              alt="graphics"
            />
            <h2 className="service-title">Un Video 3D?</h2>
            <p className="service-description">
              Vorresti un bel video in 3D e di impatto per promuovere la tua
              attività?
            </p>
          </div>
        </div>

        <div
          className="service-content-container col-lg-3"
          data-aos="fade-left"
        >
          <div className="service-content">
            <img
              src={require("../../../assets/images/services/app.webp")}
              alt="video_making"
            />
            <h2 className="service-title">Un'APP o una WEB APP?</h2>
            <p className="service-description">
              Creazione di App e WEB APP
              <strong> anche con tecnologia PWA! </strong>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
