import Activity from "./Activity/Activity";
import Header from "./Header/Header";
import Hero from "./Hero/Hero";
import "./Home.scss";
import Idea from "./Idea/Idea";
import Portfolio from "./Portfolio/Portfolio";
import Services from "./Services/Services";
import Me from "./Me/Me";
import ContactForm from "./ContactForm/ContactForm";
import Footer from "./Footer/Footer";
import Loader from "./Loader/Loader";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import { useEffect } from "react";

const Home = () => {
    const onResize = () => {
        const userAgent = window.navigator.userAgent;

        let startSize = {
            width: window.outerWidth,
            height: window.outerHeight,
        };

        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
            if (
                startSize.width !== window.outerWidth ||
                startSize.height !== window.outerHeight
            ) {
                startSize = {
                    width: window.outerWidth,
                    height: window.outerHeight,
                };

                ScrollTrigger.refresh();
            }
        } else {
            ScrollTrigger.refresh();
        }
    };

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, []);

    return (
        <>
            <Loader />
            {/* <Header /> */}
            <Hero />
            <Services />
            <Idea />
            <Portfolio />
            <Activity />
            <Me />
            <ContactForm />
            <Footer />
        </>
    );
};

export default Home;
